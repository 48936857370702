// src/lib/appwrite-config.js
import { Client, Account, Databases } from 'appwrite';

// Initialize the client
const client = new Client()
    .setEndpoint('https://cloud.appwrite.io/v1')
    .setProject(import.meta.env.VITE_APPWRITE_PROJECT_ID || ''); // Fallback to empty string if not set

// Initialize Appwrite services
const account = new Account(client);
const databases = new Databases(client);

// Database and Collection IDs with required environment variables
const DATABASES = {
    MAIN: import.meta.env.VITE_APPWRITE_DATABASE_ID || 'organoid_db',
    collections: {
        USERS: 'organoid_users',
        CHAT_HISTORY: '67422dc4002685699010'
    }
};

// Export a function to verify configuration
const verifyConfig = () => {
    const requiredVars = {
        'Project ID': import.meta.env.VITE_APPWRITE_PROJECT_ID,
        'Database ID': import.meta.env.VITE_APPWRITE_DATABASE_ID,
    };

    const missingVars = Object.entries(requiredVars)
        .filter(([, value]) => !value)
        .map(([name]) => name);

    if (missingVars.length > 0) {
        console.error('Missing required environment variables:', missingVars.join(', '));
        return false;
    }
    return true;
};

export { client, account, databases, DATABASES, verifyConfig };