import { Send, Paperclip, X, Sparkles, CornerDownRight } from 'lucide-react';
import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function ChatInput({ onSubmit, isLoading }) {
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [isShiftPressed, setIsShiftPressed] = useState(false);
    const fileInputRef = useRef(null);
    const textareaRef = useRef(null);

    useEffect(() => {
        adjustTextareaHeight();
    }, [message]);

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${Math.min(textarea.scrollHeight, 400)}px`; // Increased max height
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if ((!message.trim() && files.length === 0) || isLoading) return;
        onSubmit(message, files);
        setMessage('');
        setFiles([]);
        if (textareaRef.current) {
            textareaRef.current.style.height = '50px';
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Shift') {
            setIsShiftPressed(true);
        }
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                // Show visual feedback for newline
                const cursorPosition = e.target.selectionStart;
                const textBeforeCursor = message.slice(0, cursorPosition);
                const textAfterCursor = message.slice(cursorPosition);
                setMessage(textBeforeCursor + '\n' + textAfterCursor);
                setTimeout(() => {
                    e.target.selectionStart = e.target.selectionEnd = cursorPosition + 1;
                }, 0);
            } else {
                e.preventDefault();
                handleSubmit(e);
            }
        }
    };

    const handleKeyUp = (e) => {
        if (e.key === 'Shift') {
            setIsShiftPressed(false);
        }
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(prev => [...prev, ...selectedFiles]);
    };

    const removeFile = (index) => {
        setFiles(prev => prev.filter((_, i) => i !== index));
    };

    const handlePaste = (e) => {
        const items = e.clipboardData?.items;
        if (!items) return;

        const pastedFiles = Array.from(items)
            .filter(item => item.kind === 'file')
            .map(item => item.getAsFile())
            .filter(Boolean);

        if (pastedFiles.length > 0) {
            setFiles(prev => [...prev, ...pastedFiles]);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-3"
        >
            {/* Shift+Enter Indicator */}
            <AnimatePresence>
                {isShiftPressed && (
                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        className="absolute -top-8 left-1/2 transform -translate-x-1/2
                                 px-3 py-1.5 rounded-lg text-xs
                                 bg-black/80 dark:bg-white/80
                                 text-white dark:text-black
                                 flex items-center gap-2 backdrop-blur-sm
                                 border border-white/20 dark:border-black/20
                                 shadow-lg"
                    >
                        <CornerDownRight className="w-3 h-3" />
                        Press Enter to send, Shift+Enter for newline
                    </motion.div>
                )}
            </AnimatePresence>

            {/* File Attachments */}
            <AnimatePresence>
                {files.length > 0 && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        className="flex flex-wrap gap-2 p-3 rounded-xl
                                 bg-white/50 dark:bg-black/50
                                 border border-black/10 dark:border-white/10
                                 shadow-sm backdrop-blur-sm
                                 ring-1 ring-black/10 dark:ring-white/10
                                 hover:ring-green-500/20 transition-all duration-300"
                    >
                        {files.map((file, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.8 }}
                                className="group relative bg-white dark:bg-black
                                         border border-black/10 dark:border-white/10
                                         hover:border-green-500/20
                                         rounded-lg p-2 pr-8 flex items-center gap-2
                                         shadow-sm hover:shadow-green-500/10
                                         transition-all duration-300"
                            >
                                <Paperclip className="w-4 h-4 text-black/60 dark:text-white/60" />
                                <span className="text-sm truncate max-w-[200px] text-black dark:text-white">
                                    {file.name}
                                </span>
                                <button
                                    onClick={() => removeFile(index)}
                                    className="absolute right-2 top-1/2 -translate-y-1/2
                                             p-1 rounded-md text-black dark:text-white
                                             hover:bg-red-500/10
                                             hover:text-red-500 dark:hover:text-red-400
                                             transition-all duration-200"
                                >
                                    <X className="w-3 h-3" />
                                </button>
                            </motion.div>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Main Input Form */}
            <motion.form
                onSubmit={handleSubmit}
                className="relative flex items-end gap-2"
            >
                <div className="flex-1 relative group">
                    <div className="absolute left-3 bottom-3 z-10">
                        <motion.button
                            type="button"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => fileInputRef.current?.click()}
                            className="p-2 rounded-lg hover:bg-black/5 dark:hover:bg-white/5
                                     border border-transparent
                                     hover:border-green-500/20
                                     hover:shadow-green-500/10
                                     transition-all duration-200 group"
                        >
                            <Paperclip className="w-5 h-5 text-black/40 dark:text-white/40
                                                group-hover:text-green-500
                                                transition-colors" />
                        </motion.button>
                    </div>

                    <textarea
                        ref={textareaRef}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyUp}
                        onPaste={handlePaste}
                        placeholder={isLoading ? "AI is thinking..." : "Type a message..."}
                        rows={1}
                        className="w-full pl-14 pr-4 py-4 rounded-xl
                                 bg-white dark:bg-black
                                 border border-black/10 dark:border-white/10
                                 text-black dark:text-white
                                 placeholder-black/40 dark:placeholder-white/40
                                 focus:border-green-500/20
                                 focus:ring-2 focus:ring-green-500/10
                                 group-hover:border-green-500/10
                                 group-hover:shadow-green-500/5
                                 disabled:opacity-50 disabled:cursor-not-allowed
                                 transition-all duration-200
                                 resize-none min-h-[50px] max-h-[400px]
                                 custom-scrollbar"
                        disabled={isLoading}
                    />

                    <input
                        ref={fileInputRef}
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        className="hidden"
                        accept="image/*,.pdf,.doc,.docx,.txt"
                    />
                </div>

                <motion.button
                    type="submit"
                    disabled={(!message.trim() && files.length === 0) || isLoading}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="p-3 rounded-xl flex-shrink-0
                             bg-black dark:bg-white
                             text-white dark:text-black
                             disabled:opacity-50 disabled:cursor-not-allowed
                             shadow-lg hover:shadow-green-500/20
                             hover:ring-2 hover:ring-green-500/20
                             transform hover:-translate-y-0.5
                             transition-all duration-300"
                >
                    {isLoading ? (
                        <Sparkles className="w-5 h-5 animate-pulse" />
                    ) : (
                        <Send className="w-5 h-5" />
                    )}
                </motion.button>
            </motion.form>
        </motion.div>
    );
}