import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ThemeToggle from '../components/ui/ThemeToggle';
import { ChevronRight, Sparkles, X } from 'lucide-react';

const CustomAlert = ({ children, onClose }) => (
    <motion.div
        className="relative flex items-center gap-3 px-4 py-3 rounded-lg
                   bg-white dark:bg-black border border-gray-200 dark:border-gray-800
                   shadow-lg backdrop-blur-lg"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
    >
        <div className="flex-1 text-sm text-gray-600 dark:text-gray-400">{children}</div>
        <button
            onClick={onClose}
            className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-900
                     transition-colors duration-200"
        >
            <X className="w-4 h-4" />
        </button>
    </motion.div>
);

const GlowingBackground = () => {
    return (
        <div className="absolute inset-0 overflow-hidden">
            <div className="absolute -inset-[10px] opacity-50">
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                              w-96 h-96 bg-gradient-to-r from-green-300/10 to-green-500/10
                              dark:from-green-500/10 dark:to-green-700/10 rounded-full
                              blur-3xl opacity-20 animate-pulse"></div>
            </div>
            <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r
                          from-transparent via-green-500/20
                          to-transparent opacity-25"></div>
            <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r
                          from-transparent via-green-500/20
                          to-transparent opacity-25"></div>
        </div>
    );
};

const FloatingParticle = ({ delay = 0 }) => {
    const randomLeft = Math.random() * 100;
    const randomDuration = 4 + Math.random() * 4;

    return (
        <motion.div
            className="absolute w-1 h-1 bg-green-400 rounded-full opacity-20"
            initial={{ y: -10, x: `${randomLeft}%` }}
            animate={{
                y: ['0%', '100%'],
                opacity: [0.2, 0.5, 0.2]
            }}
            transition={{
                duration: randomDuration,
                delay: delay,
                repeat: Infinity,
                ease: "linear"
            }}
        />
    );
};

const ShimmerButton = ({ children, ...props }) => (
    <button
        {...props}
        className="relative w-full py-3 px-4 rounded-xl font-medium
                 bg-black dark:bg-white text-white dark:text-black
                 shadow-lg hover:shadow-xl group overflow-hidden
                 transform hover:-translate-y-0.5 hover:shadow-green-500/20
                 transition-all duration-300 disabled:opacity-50"
    >
        <span className="relative z-10 flex items-center justify-center gap-2">
            {children}
            <ChevronRight className="w-4 h-4 transform group-hover:translate-x-1
                                 transition-transform duration-300" />
        </span>
        <div className="absolute inset-0 bg-gradient-to-r from-transparent
                     via-green-500/10 to-transparent
                     translate-x-[-100%] animate-shimmer" />
    </button>
);

const GlowingInput = ({ label, error, ...props }) => (
    <div className="space-y-2">
        <label className="block text-sm font-medium">
            {label}
        </label>
        <div className="relative group">
            <input
                {...props}
                className={`w-full px-4 py-3 rounded-xl bg-gray-50/50
                        dark:bg-gray-900/50 border border-gray-200/50
                        dark:border-gray-800/50 text-black dark:text-white
                        placeholder-gray-500 dark:placeholder-gray-400
                        focus:ring-2 focus:ring-green-500/20
                        focus:border-transparent group-hover:ring-1
                        group-hover:ring-green-500/10 
                        transition-all duration-300 ${error ? 'border-red-500 focus:ring-red-500' : ''}`}
            />
            <div className="absolute -inset-px rounded-xl opacity-0
                         group-hover:opacity-100 transition-opacity
                         duration-300 pointer-events-none">
                <div className="absolute inset-0 rounded-xl bg-gradient-to-r
                             from-green-300/5 to-green-500/5 opacity-50"></div>
            </div>
        </div>
        {error && (
            <p className="text-sm text-red-500">{error}</p>
        )}
    </div>
);

const Auth = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();
    const { login, register, sendPasswordResetEmail } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            let result;
            if (isLogin) {
                result = await login(email, password);
                if (result.success) {
                    setAlertMessage('Successfully logged in!');
                    setShowAlert(true);
                    setTimeout(() => navigate('/chat'), 1000);
                }
            } else {
                if (password !== confirmPassword) {
                    setError("Passwords don't match");
                    setLoading(false);
                    return;
                }

                result = await register(email, password, name);
                if (result.success) {
                    setAlertMessage('Account created successfully!');
                    setShowAlert(true);
                    setTimeout(() => navigate('/chat'), 1000);
                }
            }

            if (!result.success) {
                setError(result.error);
            }
        } catch (error) {
            setError('An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        if (!email) {
            setError('Please enter your email address to reset password');
            return;
        }

        try {
            setLoading(true);
            const result = await sendPasswordResetEmail(email);
            if (result.success) {
                setAlertMessage('Password reset email sent! Check your inbox.');
                setShowAlert(true);
                setError('');
            } else {
                setError(result.error);
            }
        } catch (error) {
            setError('Failed to send reset email');
        } finally {
            setLoading(false);
        }
    };

    const toggleMode = () => {
        setIsLogin(!isLogin);
        setError('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setName('');
    };

    return (
        <div className="relative min-h-screen flex flex-col bg-white dark:bg-black
                      text-black dark:text-white transition-colors duration-500">
            <GlowingBackground />
            {Array.from({ length: 20 }).map((_, i) => (
                <FloatingParticle key={i} delay={i * 0.2} />
            ))}

            <header className="relative z-10 p-6 flex justify-between items-center
                           backdrop-blur-sm bg-white/30 dark:bg-black/30
                           border-b border-green-500/20">
                <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    className="text-xl font-bold tracking-tight group"
                >
                    <span className="relative">
                        TranscendenceX
                        <span className="absolute -inset-1 rounded-lg bg-gradient-to-r
                                     from-green-300/10 to-green-500/10
                                     opacity-0 group-hover:opacity-100
                                     transition-opacity duration-300"></span>
                    </span>
                </motion.div>
                <ThemeToggle />
            </header>

            <main className="relative z-10 flex-1 flex flex-col items-center justify-center p-6">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="mb-12 text-center space-y-4"
                >
                    <h1 className="relative text-6xl font-bold tracking-tighter">
                        <span className="relative">
                            TranscendenceX
                            <span className="absolute -inset-6 rounded-2xl bg-gradient-to-r
                                         from-green-300/10 to-green-500/10
                                         opacity-5 blur-2xl"></span>
                        </span>
                    </h1>
                    <p className="text-xl text-gray-600 dark:text-gray-400">
                        Experience the next generation of AI
                    </p>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="w-full max-w-md"
                >
                    <div className="relative backdrop-blur-xl bg-white/80 dark:bg-black/80
                                rounded-2xl p-8 shadow-2xl ring-1 ring-green-500/20
                                hover:ring-green-500/30 hover:shadow-green-500/10
                                transition-all duration-300">
                        <div className="absolute inset-0 -z-10 bg-gradient-to-b
                                    from-green-300/5 to-green-500/5
                                    dark:from-green-500/5 dark:to-green-700/5
                                    rounded-2xl blur-xl"></div>

                        <div className="relative flex rounded-xl overflow-hidden mb-8 p-1
                                    bg-gray-100/50 dark:bg-gray-900/50 ring-1
                                    ring-green-500/20">
                            {['Sign In', 'Sign Up'].map((text, i) => (
                                <button
                                    key={text}
                                    onClick={() => toggleMode()}
                                    className={`flex-1 py-3 px-4 text-sm font-medium rounded-lg 
                                            transition-all duration-300 ${
                                        (i === 0 ? isLogin : !isLogin)
                                            ? 'bg-black dark:bg-white text-white dark:text-black shadow-lg'
                                            : 'text-gray-500 dark:text-gray-400 hover:text-black dark:hover:text-white'
                                    }`}
                                >
                                    {text}
                                </button>
                            ))}
                        </div>

                        <AnimatePresence mode="wait">
                            <motion.form
                                key={isLogin ? 'login' : 'register'}
                                initial={{ opacity: 0, x: isLogin ? -20 : 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: isLogin ? 20 : -20 }}
                                onSubmit={handleSubmit}
                                className="space-y-6"
                            >
                                {error && (
                                    <div className="text-red-500 text-sm text-center bg-red-50 dark:bg-red-900/10 p-3 rounded-lg">
                                        {error}
                                    </div>
                                )}

                                <GlowingInput
                                    label="Email Address"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="name@example.com"
                                    required
                                />

                                {!isLogin && (
                                    <GlowingInput
                                        label="Full Name"
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Enter your full name"
                                        required
                                    />
                                )}

                                <GlowingInput
                                    label="Password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder={isLogin ? "Enter your password" : "Create a password"}
                                    required
                                />

                                {!isLogin && (
                                    <GlowingInput
                                        label="Confirm Password"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm your password"
                                        required
                                    />
                                )}

                                {isLogin && (
                                    <button
                                        onClick={handleForgotPassword}
                                        className="text-sm text-gray-500 hover:text-green-500
                                                dark:hover:text-green-400 transition-colors block w-full text-left"
                                    >
                                        Forgot Password?
                                    </button>
                                )}

                                <ShimmerButton
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? 'Processing...' : (isLogin ? 'Sign In' : 'Create Account')}
                                </ShimmerButton>
                            </motion.form>
                        </AnimatePresence>
                    </div>
                </motion.div>
            </main>

            <footer className="relative z-10 py-6 text-center text-sm text-gray-500 dark:text-gray-400">
                © 2024 TranscendenceX. All rights reserved.
            </footer>

            <AnimatePresence>
                {showAlert && (
                    <motion.div className="fixed bottom-4 right-4 z-50">
                        <CustomAlert onClose={() => setShowAlert(false)}>
                            {alertMessage}
                        </CustomAlert>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Auth;