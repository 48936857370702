// App.jsx
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { useAuth } from './contexts/AuthContext';
import Auth from './pages/Auth';
import Chat from './pages/Chat';
import LandingPage from './pages/LandingPage';
import About from './pages/About';

const LoadingSpinner = () => (
    <div className="h-screen w-screen flex items-center justify-center bg-white dark:bg-black">
        <div className="flex flex-col items-center space-y-4">
            <div className="w-8 h-8 border-2 border-black/20 dark:border-white/20 border-t-black dark:border-t-white rounded-full animate-spin" />
            <p className="text-sm">Loading...</p>
        </div>
    </div>
);

const ProtectedRoute = ({ children }) => {
    const { user, loading } = useAuth();
    if (loading) return <LoadingSpinner />;
    if (!user) return <Navigate to="/auth" replace />;
    return children;
};

const AuthRoute = ({ children }) => {
    const { user, loading } = useAuth();
    if (loading) return <LoadingSpinner />;
    if (user) return <Navigate to="/chat" replace />;
    return children;
};

const PublicRoute = ({ children }) => {
    const { loading } = useAuth();
    if (loading) return <LoadingSpinner />;
    return children;
};

const NotFound = () => (
    <div className="h-screen w-screen flex flex-col items-center justify-center bg-white dark:bg-black text-black dark:text-white">
        <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
        <p className="text-black/60 dark:text-white/60 mb-8">
            The page you're looking for doesn't exist.
        </p>
        <button
            onClick={() => window.location.href = '/'}
            className="px-6 py-3 bg-black dark:bg-white text-white dark:text-black rounded-lg hover:opacity-90 transition-all duration-300 font-medium"
        >
            Go Home
        </button>
    </div>
);

export default function App() {
    return (
        <AuthProvider>
            <ThemeProvider>
                <BrowserRouter>
                    <div className="w-full min-h-screen bg-white dark:bg-black text-black dark:text-white">
                        <Routes>
                            {/* Public Routes */}
                            <Route
                                path="/"
                                element={
                                    <PublicRoute>
                                        <LandingPage />
                                    </PublicRoute>
                                }
                            />
                            <Route
                                path="/about"
                                element={
                                    <PublicRoute>
                                        <About />
                                    </PublicRoute>
                                }
                            />

                            {/* Auth Route */}
                            <Route
                                path="/auth"
                                element={
                                    <AuthRoute>
                                        <Auth />
                                    </AuthRoute>
                                }
                            />

                            {/* Protected Chat Routes */}
                            <Route
                                path="/chat/*"
                                element={
                                    <ProtectedRoute>
                                        <Chat />
                                    </ProtectedRoute>
                                }
                            />

                            {/* 404 Route */}
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>
                </BrowserRouter>
            </ThemeProvider>
        </AuthProvider>
    );
}