import { motion, AnimatePresence } from 'framer-motion';
import {
    MessageSquare,
    Plus,
    Settings,
    X,
    Trash2,
    Moon,
    Sun,
    MessagesSquare,
    PanelLeft,
    Menu,
    ChevronLeft
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';

const SettingsPopup = ({ onClose, onLogout }) => {
    const { theme, toggleTheme } = useTheme();

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="absolute bottom-16 left-4 right-4 p-4 rounded-xl
                      backdrop-blur-xl bg-white/90 dark:bg-black/90
                      border border-green-500/20
                      shadow-lg hover:shadow-green-500/10
                      transition-all duration-300"
        >
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <h3 className="text-sm font-medium">Settings</h3>
                    <button onClick={onClose}
                            className="p-1.5 rounded-lg hover:bg-green-500/5
                                     transition-colors duration-200">
                        <X size={16} />
                    </button>
                </div>

                <div className="h-px bg-gradient-to-r from-transparent
                              via-green-500/20 to-transparent" />

                <div className="flex items-center justify-between">
                    <span className="text-sm">Theme</span>
                    <button
                        onClick={toggleTheme}
                        className="p-2 rounded-lg hover:bg-green-500/5
                                 transition-colors duration-200"
                    >
                        {theme === 'dark' ? <Sun size={16} /> : <Moon size={16} />}
                    </button>
                </div>

                <div className="h-px bg-gradient-to-r from-transparent
                              via-green-500/20 to-transparent" />

                <button
                    onClick={onLogout}
                    className="w-full p-2 rounded-lg text-sm text-red-500 dark:text-red-400
                             hover:bg-red-50 dark:hover:bg-red-900/10
                             transition-colors duration-200"
                >
                    Sign Out
                </button>
            </div>
        </motion.div>
    );
};

const TimeSection = ({ title, children }) => (
    <div className="py-2">
        <div className="px-4 py-2">
            <h3 className="text-xs text-gray-500 dark:text-gray-400 font-medium">
                {title}
            </h3>
        </div>
        <div className="space-y-0.5">
            {children}
        </div>
    </div>
);

const ChatItem = ({ chat, onSelect, onDelete }) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/chat/${chat.id}`);
        onSelect();
    };

    return (
        <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="px-2"
        >
            <div
                onClick={handleClick}
                className="group relative w-full p-3 rounded-lg flex items-center
                         cursor-pointer gap-3 transition-all duration-200
                         hover:bg-green-500/5 border border-transparent
                         hover:border-green-500/10 hover:shadow-sm
                         hover:shadow-green-500/10"
            >
                <MessagesSquare size={16} className="shrink-0 text-green-500/60" />
                <span className="text-sm truncate flex-1 group-hover:text-green-500/90
                               transition-colors duration-200">
                    {chat.title}
                </span>
                <motion.button
                    initial={false}
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete(chat.id);
                    }}
                    className="opacity-0 group-hover:opacity-100 p-1.5 rounded-lg
                              hover:bg-red-100 dark:hover:bg-red-900/20
                              text-red-500/50 hover:text-red-500
                              transition-all duration-200"
                >
                    <Trash2 size={14} />
                </motion.button>
            </div>
        </motion.div>
    );
};

// Mobile Toggle Button Component
const MobileToggle = ({ isOpen, setIsOpen }) => (
    <AnimatePresence mode="wait">
        {!isOpen ? (
            <motion.button
                key="open"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => setIsOpen(true)}
                className="fixed left-4 top-4 z-50 p-2 rounded-lg lg:hidden
                          bg-white/80 dark:bg-black/80 backdrop-blur-sm
                          border border-green-500/20 shadow-lg
                          hover:shadow-green-500/10 hover:bg-green-500/5
                          transition-all duration-300"
            >
                <Menu size={20} className="text-green-500" />
            </motion.button>
        ) : (
            <motion.button
                key="close"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => setIsOpen(false)}
                className="fixed right-4 top-4 z-50 p-2 rounded-lg lg:hidden
                          bg-white/80 dark:bg-black/80 backdrop-blur-sm
                          border border-green-500/20 shadow-lg
                          hover:shadow-green-500/10 hover:bg-green-500/5
                          transition-all duration-300"
            >
                <X size={20} className="text-green-500" />
            </motion.button>
        )}
    </AnimatePresence>
);

export default function Sidebar({ isOpen = true, setIsOpen, onNewChat, activeChat, onSelectChat, chats = [], onDeleteChat }) {
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const [showSettings, setShowSettings] = useState(false);

    // Group chats by timeframe
    const todayChats = chats.filter(chat => {
        const today = new Date();
        const chatDate = new Date(chat.createdAt);
        return chatDate.toDateString() === today.toDateString();
    });

    const last7DaysChats = chats.filter(chat => {
        const date = new Date(chat.createdAt);
        const today = new Date();
        const sevenDaysAgo = new Date(today.setDate(today.getDate() - 7));
        return date > sevenDaysAgo && date.toDateString() !== new Date().toDateString();
    });

    const olderChats = chats.filter(chat => {
        const date = new Date(chat.createdAt);
        const today = new Date();
        const sevenDaysAgo = new Date(today.setDate(today.getDate() - 7));
        return date <= sevenDaysAgo;
    });

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/', { replace: true });
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <>
            <MobileToggle isOpen={isOpen} setIsOpen={setIsOpen} />

            <AnimatePresence>
                {isOpen && (
                    <>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.3 }}
                            exit={{ opacity: 0 }}
                            className="fixed inset-0 bg-black dark:bg-white z-40 lg:hidden"
                            onClick={() => setIsOpen(false)}
                        />

                        <motion.aside
                            initial={{ x: -300 }}
                            animate={{ x: 0 }}
                            exit={{ x: -300 }}
                            className="fixed inset-y-0 left-0 w-72 z-50 flex flex-col
                                   bg-white/80 dark:bg-black/80 backdrop-blur-xl
                                   border-r border-green-500/20 shadow-lg shadow-green-500/5"
                        >
                            {/* Header */}
                            <div className="flex items-center justify-between p-4
                                        border-b border-green-500/20">
                                <h2 className="text-lg font-medium bg-gradient-to-r
                                           from-green-400 to-green-600 bg-clip-text text-transparent">
                                    Your Chats
                                </h2>

                                <motion.button
                                    onClick={onNewChat}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="p-2 rounded-lg bg-gradient-to-r from-green-500 to-green-600
                                             text-white shadow-lg hover:shadow-green-500/20
                                             transition-all duration-300"
                                >
                                    <Plus size={16} />
                                </motion.button>
                            </div>

                            {/* Chat Lists */}
                            <div className="flex-1 overflow-y-auto custom-scrollbar">
                                {todayChats.length > 0 && (
                                    <TimeSection title="Today">
                                        {todayChats.map((chat) => (
                                            <ChatItem
                                                key={chat.id}
                                                chat={chat}
                                                onSelect={() => onSelectChat(chat)}
                                                onDelete={onDeleteChat}
                                            />
                                        ))}
                                    </TimeSection>
                                )}

                                {last7DaysChats.length > 0 && (
                                    <TimeSection title="Previous 7 Days">
                                        {last7DaysChats.map((chat) => (
                                            <ChatItem
                                                key={chat.id}
                                                chat={chat}
                                                onSelect={() => onSelectChat(chat)}
                                                onDelete={onDeleteChat}
                                            />
                                        ))}
                                    </TimeSection>
                                )}

                                {olderChats.length > 0 && (
                                    <TimeSection title="Previous 30 Days">
                                        {olderChats.map((chat) => (
                                            <ChatItem
                                                key={chat.id}
                                                chat={chat}
                                                onSelect={() => onSelectChat(chat)}
                                                onDelete={onDeleteChat}
                                            />
                                        ))}
                                    </TimeSection>
                                )}

                                {chats.length === 0 && (
                                    <div className="flex flex-col items-center justify-center h-40 text-center">
                                        <MessagesSquare size={24} className="text-green-500/40 mb-2" />
                                        <p className="text-sm text-gray-500 dark:text-gray-400">
                                            No conversations yet
                                        </p>
                                    </div>
                                )}
                            </div>

                            {/* Settings Button at Bottom */}
                            <div className="p-4 border-t border-green-500/20">
                                <motion.button
                                    onClick={() => setShowSettings(!showSettings)}
                                    className="w-full p-2 rounded-lg flex items-center gap-3
                                             hover:bg-green-500/5 border border-transparent
                                             hover:border-green-500/10 hover:shadow-sm
                                             hover:shadow-green-500/10
                                             transition-all duration-200"
                                >
                                    <Settings size={16} className="text-green-500/60" />
                                    <span className="text-sm">Settings</span>
                                </motion.button>
                            </div>

                            {/* Settings Popup */}
                            <AnimatePresence>
                                {showSettings && (
                                    <SettingsPopup
                                        onClose={() => setShowSettings(false)}
                                        onLogout={handleLogout}
                                    />
                                )}
                            </AnimatePresence>
                        </motion.aside>
                    </>
                )}
            </AnimatePresence>
        </>
    );
}