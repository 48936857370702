import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
    const [theme, setTheme] = useState(() => {
        if (typeof window !== 'undefined') {
            const savedTheme = localStorage.getItem('theme');
            if (savedTheme) {
                console.log('Using saved theme:', savedTheme);
                return savedTheme;
            }
            // Default to dark theme instead of checking system preference
            console.log('Using default dark theme');
            return 'dark';
        }
        return 'dark'; // Changed default from 'light' to 'dark'
    });

    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => {
            if (!localStorage.getItem('theme')) {
                console.log('System theme changed:', e.matches ? 'dark' : 'light');
                // Removed this line to prevent system preference from overriding dark default
                // setTheme(e.matches ? 'dark' : 'light');
            }
        };

        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    useEffect(() => {
        console.log('Applying theme:', theme);
        const root = window.document.documentElement;
        const computedStyle = getComputedStyle(root);
        const oldTransition = computedStyle.getPropertyValue('transition');

        // Apply smooth transition
        root.style.transition = 'background-color 0.2s ease-in-out, color 0.2s ease-in-out';

        // Remove old theme classes and add new one
        root.classList.remove('light', 'dark');
        root.classList.add(theme);

        // Save theme preference
        localStorage.setItem('theme', theme);

        // Reset transition after theme change
        const timer = setTimeout(() => {
            root.style.transition = oldTransition;
        }, 200);

        return () => clearTimeout(timer);
    }, [theme]);

    const toggleTheme = () => {
        setIsTransitioning(true);
        const newTheme = theme === 'light' ? 'dark' : 'light';
        console.log('Toggling theme to:', newTheme);

        requestAnimationFrame(() => {
            setTheme(newTheme);
            setTimeout(() => setIsTransitioning(false), 200);
        });
    };

    const contextValue = useMemo(() => ({
        theme,
        toggleTheme,
        isTransitioning,
        isDark: theme === 'dark'
    }), [theme, isTransitioning]);

    return (
        <ThemeContext.Provider value={contextValue}>
            <div className={`${theme} transition-colors duration-200`}>
                <AnimatePresence>
                    {isTransitioning && (
                        <motion.div
                            key="theme-transition"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.3 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2, ease: "easeInOut" }}
                            className="fixed inset-0 bg-black pointer-events-none z-50 backdrop-blur-sm"
                        />
                    )}
                </AnimatePresence>
                {children}
            </div>
        </ThemeContext.Provider>
    );
}

export function useTheme() {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
}