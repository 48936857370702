import React from 'react';
import { ArrowRight, Brain, Network, Users, Sparkles, ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';

const customStyles = `
@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0px); }
}

@keyframes pulse-green {
    0%, 100% { background-color: rgba(34, 197, 94, 0.1); }
    50% { background-color: rgba(34, 197, 94, 0.2); }
}

@keyframes slide-up {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

@keyframes glow {
    0%, 100% { box-shadow: 0 0 20px rgba(34, 197, 94, 0.3); }
    50% { box-shadow: 0 0 40px rgba(34, 197, 94, 0.5); }
}

@keyframes glow-text {
    0%, 100% {
        text-shadow: 
            0 0 5px rgba(34, 197, 94, 0.2),
            0 0 10px rgba(34, 197, 94, 0.2),
            inset 0 0 15px rgba(34, 197, 94, 0.2);
        -webkit-text-stroke: 1px rgba(34, 197, 94, 0.1);
    }
    50% {
        text-shadow: 
            0 0 10px rgba(34, 197, 94, 0.4),
            0 0 20px rgba(34, 197, 94, 0.4),
            inset 0 0 30px rgba(34, 197, 94, 0.4);
        -webkit-text-stroke: 1px rgba(34, 197, 94, 0.3);
    }
}

.hero-text-glow {
    background: linear-gradient(to right, rgba(34, 197, 94, 0.1), rgba(34, 197, 94, 0.2));
    -webkit-background-clip: text;
    background-clip: text;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 700;
    animation: glow-text 3s ease-in-out infinite;
}

.dark .hero-text-glow {
    color: rgba(255, 255, 255, 0.9);
}

.green-text-highlight {
    background: linear-gradient(90deg, rgba(34, 197, 94, 0.1) 0%, rgba(34, 197, 94, 0.2) 50%, rgba(34, 197, 94, 0.1) 100%);
    border-radius: 8px;
    padding: 16px 24px;
    border: 1px solid rgba(34, 197, 94, 0.2);
}

.animate-glow-text {
    animation: glow-text 3s ease-in-out infinite;
}

.animate-float {
    animation: float 6s ease-in-out infinite;
}

.animate-slide-up {
    animation: slide-up 0.6s ease-out forwards;
}

.animate-glow {
    animation: glow 2s ease-in-out infinite;
}

.animate-pulse-green {
    animation: pulse-green 2s ease-in-out infinite;
}

.bg-grid-black {
    background-size: 30px 30px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
                      linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
}

.bg-grid-white {
    background-size: 30px 30px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
                      linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
}

.text-gradient {
    background: linear-gradient(to right, #000 0%, #22c55e 50%, #000 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 200% auto;
    animation: textGradient 3s linear infinite;
}
.text-gradient2 {
    background: linear-gradient(to right, #000 0%, #22c55e 20%, #000 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 200% auto;
    animation: textGradient 8s linear infinite;
 
}

.dark .text-gradient2 {
    background: linear-gradient(to right, #fff 0%, #22c55e 20%, #fff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 200% auto;
    animation: textGradient 8s linear infinite;
 
}

.dark .text-gradient {
    background: linear-gradient(to right, #fff 0%, #22c55e 50%, #fff 100%);
    -webkit-background-clip: text;
    background-clip: text;
}

@keyframes textGradient {
    to {
        background-position: 200% center;
    }
}

.hover-green-glow:hover {
    box-shadow: 0 0 20px rgba(34, 197, 94, 0.5);
}

.green-button {
    background: linear-gradient(to right, #22c55e, #16a34a);
    color: white;
    transition: all 0.3s ease;
}

.green-button:hover {
    background: linear-gradient(to right, #16a34a, #15803d);
    transform: scale(1.05);
    box-shadow: 0 0 30px rgba(34, 197, 94, 0.4);
}

.text-glow-internal {
    background: linear-gradient(to right,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(34, 197, 94, 0.7) 15%,
        rgba(34, 197, 94, 0.8) 30%,
        rgba(34, 197, 94, 0.7) 45%,
        rgba(0, 0, 0, 0.8) 60%,
        rgba(0, 0, 0, 0.8) 100%
    );
    background-size: 300% auto;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: subtleGlowingWave 6s ease-in-out infinite;
}

.dark .text-glow-internal {
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(34, 197, 94, 0.7) 15%,
        rgba(34, 197, 94, 0.8) 30%,
        rgba(34, 197, 94, 0.7) 45%,
        rgba(255, 255, 255, 0.9) 60%,
        rgba(255, 255, 255, 0.9) 100%
    );
    background-size: 300% auto;
    -webkit-background-clip: text;
    background-clip: text;
}

@keyframes subtleGlowingWave {
    0% {
        background-position: right center;
    }
    100% {
        background-position: left center;
    }
}
`;

const HeroSection = () => (
    <header className="min-h-screen relative overflow-hidden bg-white dark:bg-black">
        <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[url('/src/assets/hero.jpg')] bg-cover bg-center opacity-300 dark:opacity-90"></div>
            <div className="absolute inset-0 bg-gradient-to-b from-white via-white/290 to-white/280 dark:from-black dark:via-black/190 dark:to-black/180"></div>
            <div className="absolute inset-0 bg-gradient-to-r from-green-300/5 to-green-300/10 dark:from-green-500/10 dark:to-green-500/20 animate-pulse"></div>
        </div>

        <div className="absolute inset-0 overflow-hidden">
            {[...Array(3)].map((_, i) => (
                <div
                    key={i}
                    className="absolute w-96 h-96 bg-gradient-to-r from-green-300/10 to-green-500/10 dark:from-green-500/10 dark:to-green-700/10 rounded-full blur-3xl animate-float"
                    style={{
                        top: `${Math.random() * 100}%`,
                        left: `${Math.random() * 100}%`,
                        animationDelay: `${i * 0.5}s`,
                    }}
                />
            ))}
        </div>

        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 lg:pt-40">
            <div className="space-y-8 max-w-4xl animate-slide-up">
                <div className="inline-flex items-center px-4 py-2 rounded-full bg-green-500/10 dark:bg-green-500/20 text-sm font-medium backdrop-blur-sm border border-green-500/20 animate-pulse-green">
                    <Sparkles className="w-4 h-4 mr-2 text-green-500" />
                    Pioneering Organoid Intelligence
                </div>

                <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold space-y-4">
                    <span className="block text-gradient">
                        Research at the Frontier
                    </span>
                    <span className="block text-black dark:text-white">
                        of Organoid Intelligence
                    </span>
                </h1>

                <p className="text-xl sm:text-2xl max-w-2xl font-bold text-gradient2">
                    TranscendenceX is the world's first AI SaaS platform dedicated to advancing research in the fields
                    of Organoid Intelligence and biocomputing.
                </p>

                <div className="flex flex-col sm:flex-row gap-4 pt-4">
                    <Link
                        to="/chat"
                        className="group relative px-8 py-4 bg-black dark:bg-white text-white dark:text-black rounded-xl overflow-hidden transition-all duration-300 hover:scale-105 hover-green-glow"
                    >
                        <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-green-400 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                        <span className="relative flex items-center justify-center font-medium text-lg">
                            Try Now
                            <ArrowRight className="ml-2 w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" />
                        </span>
                        <div className="absolute inset-0 transform translate-x-full group-hover:translate-x-[-100%] transition-transform duration-1000 bg-gradient-to-r from-transparent via-green-500/20 to-transparent"></div>
                    </Link>

                    <Link
                        to="/about"
                        className="group px-8 py-4 green-button rounded-xl transition-all duration-300"
                    >
                        <span className="flex items-center justify-center font-medium text-lg">
                            Learn More
                            <ArrowRight className="ml-2 w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" />
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    </header>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
    <div className="relative group animate-slide-up">
        <div className="absolute inset-0 bg-gradient-to-r from-green-300/20 to-green-500/20 dark:from-green-500/20 dark:to-green-700/20 rounded-2xl blur-xl transition-opacity duration-300 opacity-0 group-hover:opacity-100"></div>
        <div className="relative p-8 rounded-2xl bg-white/80 dark:bg-black/80 border border-green-500/20 backdrop-blur-sm hover:border-green-500/40 transition-all duration-300 group-hover:scale-105 hover-green-glow">
            <div className="h-12 w-12 rounded-xl bg-gradient-to-r from-green-500 to-green-600 flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
                <Icon className="h-6 w-6 text-white" />
            </div>
            <h3 className="text-xl font-bold mb-3 text-black dark:text-white">{title}</h3>
            <p className="text-black/70 dark:text-white/70">{description}</p>
        </div>
    </div>
);

const FeaturesSection = () => (
    <section className="relative py-24 overflow-hidden bg-gradient-to-b from-white to-gray-50 dark:from-black dark:to-gray-900 border-t border-green-500/20">
        <div className="absolute inset-0">
            <div className="absolute inset-0 bg-grid-black/[0.02] dark:bg-grid-white/[0.02]" />
            <div className="absolute inset-0 bg-gradient-to-r from-green-500/5 to-transparent animate-pulse"></div>
        </div>

        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16 space-y-4 animate-slide-up">
                <div className="inline-flex items-center px-4 py-2 rounded-full bg-green-500/10 dark:bg-green-500/20 text-sm font-medium border border-green-500/20">
                    <Sparkles className="w-4 h-4 mr-2 text-green-500" />
                    Our Capabilities
                </div>
                <h2 className="text-3xl sm:text-4xl font-bold text-gradient leading-relaxed pb-2">
                    Advancing the Future of Intelligence
                </h2>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <FeatureCard
                    icon={Brain}
                    title="Organoid Intelligence"
                    description="Harnessing the power of lab-grown brain organoids to create adaptive, efficient computing systems."
                />
                <FeatureCard
                    icon={Network}
                    title="Neural Integration"
                    description="Pioneering breakthroughs in biocomputing that bridge artificial and biological intelligence."
                />
                <FeatureCard
                    icon={Users}
                    title="Collaborative Research"
                    description="Building interdisciplinary teams to create revolutionary computing platforms for transformative innovation."
                />
            </div>
        </div>
    </section>
);

const MissionSection = () => (
    <section className="relative py-24 bg-white dark:bg-black border-t border-green-500/20 overflow-hidden">
        <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-green-500/5 to-transparent dark:from-green-500/10 dark:to-transparent"></div>
        </div>

        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
                <div className="space-y-8 animate-slide-up">
                    <div className="inline-flex items-center px-4 py-2 rounded-full bg-green-500/10 dark:bg-green-500/20 text-sm font-medium border border-green-500/20">
                        <Sparkles className="w-4 h-4 mr-2 text-green-500" />
                        Our Mission
                    </div>

                    <h2 className="text-3xl sm:text-4xl font-bold text-gradient leading-relaxed pb-2">
                        Reimagining Intelligence
                    </h2>

                    <div className="space-y-6">
                        <p className="text-lg text-black/70 dark:text-white/70 leading-relaxed">
                            While artificial intelligence has achieved remarkable progress, we are pioneering systems that harness the raw computational power and learning capabilities of lab-grown brain organoids to bridge the gap between artificial and biological intelligence.
                        </p>
                        <p className="text-lg text-black/70 dark:text-white/70 leading-relaxed">
                            Our mission is to create the next generation of biocomputers, powered by brain organoids, capable of learning, reasoning, and adapting in ways current silicon-based systems cannot.
                        </p>
                    </div>

                    <Link
                        to="/about"
                        className="inline-flex items-center text-lg font-medium group green-button px-6 py-3 rounded-xl"
                    >
                        Learn More
                        <ArrowRight className="ml-2 w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" />
                    </Link>
                </div>

                <div className="relative group animate-slide-up">
                    <div className="absolute inset-0 bg-gradient-to-r from-green-300/20 to-green-500/20 dark:from-green-500/20 dark:to-green-700/20 rounded-2xl blur-xl transition-opacity duration-300 opacity-0 group-hover:opacity-100"></div>
                    <div className="relative overflow-hidden rounded-2xl border border-green-500/20 group-hover:scale-105 transition-transform duration-300 hover-green-glow">
                        <img
                            src="/research.jpg"
                            alt="Our Mission"
                            className="w-full aspect-video object-cover transform group-hover:scale-105 transition-transform duration-300"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const CTASection = () => (
    <section className="relative py-32 bg-gradient-to-b from-white to-gray-50 dark:from-black dark:to-gray-900 text-black dark:text-white overflow-hidden">
        {/* Animated background elements */}
        <div className="absolute inset-0">
            <div className="absolute inset-0 bg-grid-black/[0.02] dark:bg-grid-white/[0.02]" />
            <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-full h-full max-w-7xl mx-auto grid grid-cols-2 gap-8">
                    {[...Array(4)].map((_, i) => (
                        <div
                            key={i}
                            className="absolute w-64 h-64 bg-gradient-to-r from-green-300/20 to-green-500/20 dark:from-green-500/20 dark:to-green-700/20 rounded-full blur-3xl animate-float"
                            style={{
                                top: `${Math.random() * 100}%`,
                                left: `${Math.random() * 100}%`,
                                animationDelay: `${i * 0.5}s`,
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>

        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="bg-white/80 dark:bg-black/80 backdrop-blur-lg rounded-3xl p-8 md:p-12 shadow-2xl border border-green-500/20 animate-slide-up hover-green-glow">
                <div className="max-w-4xl mx-auto text-center space-y-8">
                    <div className="inline-flex items-center px-4 py-2 rounded-full bg-green-500/10 dark:bg-green-500/20 text-sm font-medium border border-green-500/20 animate-pulse-green">
                        <Sparkles className="w-4 h-4 mr-2 text-green-500" />
                        Experience the Future of Intelligence
                    </div>

                    <h2 className="text-4xl md:text-5xl font-bold text-gradient">
                        Join Us in Shaping Tomorrow
                    </h2>

                    <p className="text-xl text-black/70 dark:text-white/70 max-w-2xl mx-auto leading-relaxed">
                        Be part of the revolution in biocomputing and organoid intelligence. Experience the next evolution in computing technology.
                    </p>

                    <div className="flex flex-col sm:flex-row items-center justify-center gap-4 pt-4">
                        <Link
                            to="/chat"
                            className="group relative w-full sm:w-auto px-8 py-4 bg-black dark:bg-white text-white dark:text-black rounded-xl overflow-hidden transition-all duration-300 hover:scale-105 hover-green-glow animate-glow"
                        >
                            {/* Animated gradient background */}
                            <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-green-400 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>

                            {/* Button content */}
                            <span className="relative flex items-center justify-center font-medium text-lg">
                                Try Now
                                <ArrowRight className="ml-2 w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" />
                            </span>

                            {/* Shine effect */}
                            <div className="absolute inset-0 transform translate-x-full group-hover:translate-x-[-100%] transition-transform duration-1000 bg-gradient-to-r from-transparent via-green-500/20 to-transparent"></div>
                        </Link>

                        <Link
                            to="/about"
                            className="group w-full sm:w-auto px-8 py-4 green-button rounded-xl transition-all duration-300"
                        >
                            <span className="flex items-center justify-center font-medium text-lg">
                                Learn More
                                <ArrowRight className="ml-2 w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" />
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const Footer = () => (
    <footer className="relative py-8 border-t border-green-500/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center text-sm text-black/60 dark:text-white/60">
                © 2024 TranscendenceX. All rights reserved.
            </div>
        </div>
    </footer>
);

const LandingPage = () => {
    return (
        <div className="min-h-screen bg-white dark:bg-black text-black dark:text-white">
            <style>{customStyles}</style>
            <Navbar />
            <HeroSection />
            <FeaturesSection />
            <MissionSection />
            <CTASection />
            <Footer />
        </div>
    );
};

export default LandingPage;