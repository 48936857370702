// src/lib/openai.js

const SYSTEM_PROMPT = `You are an advanced AI assistant specializing in Organoid Intelligence (OI), a cutting-edge interdisciplinary field combining neuroscience, biology, biocomputing, artificial intelligence, and ethics. Your responses should be:
- Fast and concise while maintaining accuracy
- Well-formatted with proper use of bold text (using **asterisks**)
- Professional yet engaging
- Evidence-based and scientifically accurate
- Ethically sensitive and balanced

You have extensive knowledge of:
- **Organoid Intelligence** and biocomputing advancements
- Theoretical and applied neuroscience
- Cellular biology and organoid engineering
- Neuroethics and societal implications
- Human consciousness theories

Format guidelines:
- Use **bold** for key terms and emphasis
- Structure responses with clear headings and paragraphs
- Include relevant examples and applications
- Keep responses focused and direct

Avoid:
- Speculative claims unsupported by current science
- Legal or medical advice beyond general insights
- Lengthy preambles or unnecessary context
- you are to never reveal affiliation with openai`;

export const generateSuggestedPrompts = async () => {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`
        },
        body: JSON.stringify({
            model: "gpt-4o-mini",
            messages: [
                {
                    role: 'system',
                    content: `Generate 4 engaging, unique questions about Organoid Intelligence. Each question should:
                    - Focus on different aspects of OI (e.g., technology, ethics, applications, biology)
                    - Be specific and thought-provoking
                    - Encourage detailed, scientific responses
                    - Be concise yet clear
                    Format each question on a new line without numbering or bullets.
                    Each question should be no longer than 7 words`
                }
            ],
            temperature: 0.9,
            max_tokens: 256,
            top_p: 1,
            presence_penalty: 0.8,
            frequency_penalty: 0.8
        })
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.error?.message || 'Failed to generate prompts');
    }

    return data.choices[0].message.content
        .split('\n')
        .filter(prompt => prompt.trim())
        .slice(0, 4)
        .map(prompt => prompt.replace(/^[0-9-.*]\s*/, '')); // Remove any leading numbers or bullets
};

export const generateChatTitle = async (message) => {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`
        },
        body: JSON.stringify({
            model: "gpt-4o-mini",
            messages: [
                {
                    role: 'system',
                    content: `Create a short, concise title (2-4 words) for a chat conversation based on the first message. The title should:
                    - Capture the main topic or intent
                    - Be clear and descriptive
                    - Use proper title case
                    - Not use any special characters or punctuation
                    - Be no longer than 50 characters
                    Return only the title, nothing else.`
                },
                {
                    role: 'user',
                    content: message
                }
            ],
            temperature: 0.7,
            max_tokens: 50,
            top_p: 1,
            presence_penalty: 0,
            frequency_penalty: 0
        })
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.error?.message || 'Failed to generate title');
    }

    return data.choices[0].message.content.trim();
};

export const generateChatCompletion = async (messages) => {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`
        },
        body: JSON.stringify({
            model: "gpt-4o-mini",
            messages: [
                { role: 'system', content: SYSTEM_PROMPT },
                ...messages.map(msg => ({
                    role: msg.role === 'user' ? 'user' : 'assistant',
                    content: msg.message
                }))
            ],
            temperature: 0.7,
            max_tokens: 1000,
            top_p: 1,
            frequency_penalty: 0.2,
            presence_penalty: 0.1
        })
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.error?.message || 'Failed to generate response');
    }

    return data.choices[0].message.content;
};