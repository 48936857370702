import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { databases, DATABASES } from '../lib/appwrite-config';
import { generateChatCompletion, generateSuggestedPrompts, generateChatTitle } from '../lib/openai';
import { ID, Query } from 'appwrite';
import ChatInput from '../components/chat/ChatInput';
import ChatLoading from '../components/chat/ChatLoading';
import Sidebar from '../components/chat/Sidebar';
import { Menu, ChevronLeft } from 'lucide-react';
import { FileIcon as LucideFileIcon, FileText as LucideFileText, Image as LucideImageIcon } from 'lucide-react';

const ChatHeader = ({ onMenuToggle, isSidebarOpen, title, messages }) => {
    const [abbreviatedTitle, setAbbreviatedTitle] = useState(title || 'New Chat');

    useEffect(() => {
        const generateTitle = async () => {
            if (messages?.length > 0 && messages[0]?.message) {
                try {
                    const newTitle = await generateChatTitle(messages[0].message);
                    setAbbreviatedTitle(newTitle);
                } catch (error) {
                    console.error('Error generating chat title:', error);
                    setAbbreviatedTitle(title?.slice(0, 30) + '...' || 'New Chat');
                }
            }
        };

        generateTitle();
    }, [messages, title]);

    return (
        <motion.header
            className="relative z-10 px-4 py-4 flex justify-between items-center
                     backdrop-blur-xl bg-white/70 dark:bg-black/70
                     border-b border-black/10 dark:border-white/10 shadow-sm">
            <div className="flex items-center gap-3">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onMenuToggle}
                    className="p-2 rounded-lg bg-white/50 dark:bg-black/50
                             border border-black/10 dark:border-white/10 shadow-sm
                             hover:shadow-green-500/10 hover:border-green-500/20
                             transition-all duration-200"
                >
                    {isSidebarOpen ? (
                        <ChevronLeft className="w-5 h-5 text-black dark:text-white" />
                    ) : (
                        <Menu className="w-5 h-5 text-black dark:text-white" />
                    )}
                </motion.button>

                <motion.h1
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-lg font-medium truncate max-w-[200px] sm:max-w-[300px]
                             text-black dark:text-white relative group"
                >
                    {abbreviatedTitle}
                    <div className="absolute inset-0 rounded-lg opacity-0
                                group-hover:opacity-100 transition-opacity duration-300
                                ring-1 ring-green-500/20" />
                </motion.h1>
            </div>

            <div className="w-10" aria-hidden="true" />
        </motion.header>
    );
};

const GlowingBackground = () => (
    <div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
        <div className="absolute -inset-[10px] opacity-50">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                          w-96 h-96 bg-gradient-to-r from-green-300/10 to-green-500/10
                          dark:from-green-500/10 dark:to-green-700/10 rounded-full
                          blur-3xl opacity-20 animate-pulse" />
        </div>
        <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r
                      from-transparent via-green-500/20
                      to-transparent opacity-25" />
        <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r
                      from-transparent via-green-500/20
                      to-transparent opacity-25" />
    </div>
);

const FloatingParticle = ({ delay = 0 }) => (
    <motion.div
        className="absolute w-1 h-1 bg-black dark:bg-white rounded-full opacity-20"
        initial={{ y: -10, opacity: 0 }}
        animate={{
            y: ['0vh', '100vh'],
            opacity: [0, 1, 0]
        }}
        transition={{
            duration: 8 + Math.random() * 4,
            delay,
            repeat: Infinity,
            ease: "linear"
        }}
        style={{ left: `${Math.random() * 100}%` }}
    />
);

const EmptyState = ({ onSendMessage, userName }) => {
    const [promptSuggestions, setPromptSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchPrompts = async () => {
            setIsLoading(true);
            try {
                const newPrompts = await generateSuggestedPrompts();
                if (newPrompts?.length) setPromptSuggestions(newPrompts);
            } catch (error) {
                console.error('Error fetching prompts:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchPrompts();
    }, []);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen w-full px-4">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="max-w-2xl w-full space-y-8"
            >
                {/* Welcome Message */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="text-center space-y-4"
                >
                    <h1 className="text-4xl font-bold text-black dark:text-white">
                        Hello, {userName}
                    </h1>
                    <p className="text-lg text-black/60 dark:text-white/60">
                        How can I help you today?
                    </p>
                </motion.div>

                {/* Main Input */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.3 }}
                    className="relative w-full"
                >
                    <input
                        type="text"
                        placeholder="Ask me anything..."
                        className="w-full p-4 pr-10 rounded-xl bg-white dark:bg-black
                                 border border-black/10 dark:border-white/10
                                 shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500/20
                                 hover:border-green-500/30 hover:shadow-green-500/10
                                 text-black dark:text-white
                                 placeholder-black/40 dark:placeholder-white/40
                                 transition-all duration-300"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.target.value.trim()) {
                                onSendMessage(e.target.value);
                                e.target.value = '';
                            }
                        }}
                    />
                </motion.div>

                {/* Suggested Messages */}
                <div className="space-y-4">
                    <h3 className="text-sm text-black/40 dark:text-white/40 text-center">
                        Try asking
                    </h3>
                    <div className="grid grid-cols-1 gap-3">
                        {isLoading ? (
                            <div className="animate-pulse space-y-3">
                                {[1, 2, 3].map((i) => (
                                    <div
                                        key={i}
                                        className="h-12 bg-black/5 dark:bg-white/5 rounded-xl"
                                    />
                                ))}
                            </div>
                        ) : (
                            promptSuggestions.map((suggestion, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, x: -20 }}
                                    animate={{
                                        opacity: 1,
                                        x: 0,
                                        transition: { delay: index * 0.1 }
                                    }}
                                >
                                    <button
                                        onClick={() => onSendMessage(suggestion)}
                                        className="w-full p-4 rounded-xl text-left
                                                 bg-white dark:bg-black
                                                 border border-black/10 dark:border-white/10
                                                 hover:border-green-500/30 hover:shadow-green-500/10
                                                 transition-all duration-300 group"
                                    >
                                        <span className="line-clamp-1 text-sm text-black dark:text-white
                                                       group-hover:text-green-500
                                                       transition-colors duration-300">
                                            {suggestion}
                                        </span>
                                    </button>
                                </motion.div>
                            ))
                        )}
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

const FileAttachment = ({ file }) => {
    if (!file) return null;

    return (
        <div className="flex items-center space-x-2 p-2 rounded-lg
                       bg-white dark:bg-black
                       border border-black/10 dark:border-white/10
                       hover:border-green-500/20 hover:shadow-green-500/10
                       transition-all duration-200">
            <div className="w-10 h-10 rounded-lg bg-black/5 dark:bg-white/5
                          flex items-center justify-center">
                <FileTypeIcon file={file} />
            </div>
            <div className="flex-1 min-w-0">
                <p className="text-sm font-medium truncate text-black dark:text-white">
                    {file.name}
                </p>
                <p className="text-xs text-black/40 dark:text-white/40">
                    {formatFileSize(file.size)}
                </p>
            </div>
        </div>
    );
};

const FileTypeIcon = ({ file }) => {
    if (!file || !file.type) {
        return <LucideFileIcon className="w-5 h-5 text-black/60 dark:text-white/60" />;
    }

    const type = file.type.split('/')[0];
    switch(type) {
        case 'image':
            return <LucideImageIcon className="w-5 h-5 text-black/60 dark:text-white/60" />;
        case 'application':
            return <LucideFileText className="w-5 h-5 text-black/60 dark:text-white/60" />;
        default:
            return <LucideFileIcon className="w-5 h-5 text-black/60 dark:text-white/60" />;
    }
};

const MessageContent = React.forwardRef(({ message, role, files }, ref) => {
    const isUser = role === 'user';

    const formattedMessage = useMemo(() => {
        if (!message) return '';
        // First remove any '###' from the message
        const withoutHashes = message.replace(/###/g, '');
        // Then handle bold text formatting
        return withoutHashes.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    }, [message]);

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            className={`flex items-start space-x-3 ${isUser ? 'justify-end' : ''}`}
        >
            <div className={`flex-1 max-w-[80%] space-y-2 ${isUser ? 'ml-auto' : ''}`}>
                {message && (
                    <motion.div
                        layout
                        className={`relative group p-4 rounded-2xl
                            ${isUser
                            ? 'bg-black dark:bg-white text-white dark:text-black'
                            : 'bg-white dark:bg-black text-black dark:text-white'} 
                            shadow-sm hover:shadow-green-500/10 transition-all duration-300`}
                    >
                        <div className="absolute inset-0 rounded-2xl ring-1 ring-black/10 dark:ring-white/10
                                    group-hover:ring-green-500/20 transition-all duration-300" />
                        <p
                            className="text-sm leading-relaxed whitespace-pre-wrap relative z-10"
                            dangerouslySetInnerHTML={{ __html: formattedMessage }}
                        />
                    </motion.div>
                )}

                {files && files.length > 0 && (
                    <div className="space-y-2">
                        {files.map((file, index) => (
                            <FileAttachment key={index} file={file} />
                        ))}
                    </div>
                )}
            </div>
        </motion.div>
    );
});

MessageContent.displayName = 'MessageContent';

const formatFileSize = (bytes) => {
    if (!bytes || bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
};

export default function Chat() {
    const { user } = useAuth();
    const { chatId } = useParams();
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [chats, setChats] = useState([]);
    const [activeChat, setActiveChat] = useState(null);
    const [userName, setUserName] = useState('');
    const [chatTitle, setChatTitle] = useState('New Chat');
    const messagesEndRef = useRef(null);

    const scrollToBottom = useCallback(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const generateAndSetChatTitle = async (message) => {
        try {
            const title = await generateChatTitle(message);
            setChatTitle(title);
            return title;
        } catch (error) {
            console.error('Error generating chat title:', error);
            return message.slice(0, 30) + '...';
        }
    };

    // Fetch user name
    useEffect(() => {
        if (user?.email) {
            const fetchUserName = async () => {
                try {
                    const response = await databases.listDocuments(
                        DATABASES.MAIN,
                        'organoid_users',
                        [
                            Query.equal('email', user.email)
                        ]
                    );

                    if (response && response.documents && response.documents.length > 0) {
                        setUserName(response.documents[0].name);
                    } else {
                        console.log('No user document found for email:', user.email);
                        setUserName('User');
                    }
                } catch (error) {
                    console.error('Error fetching user name:', error);
                    setUserName('User');
                }
            };
            fetchUserName();
        }
    }, [user]);

    // Fetch all chats
    useEffect(() => {
        if (user?.$id) {
            fetchChats();
        }
    }, [user]);

    // Handle chat ID from URL
    useEffect(() => {
        if (chatId && chats.length > 0) {
            const chat = chats.find(c => c.id === chatId);
            if (chat) {
                setActiveChat(chat);
                setMessages(chat.messages);
                setChatTitle(chat.title || 'New Chat');
            } else {
                navigate('/chat');
            }
        }
    }, [chatId, chats, navigate]);

    useEffect(() => {
        scrollToBottom();
    }, [messages, scrollToBottom]);

    const fetchChats = async () => {
        if (!user?.$id) return;

        try {
            const response = await databases.listDocuments(
                DATABASES.MAIN,
                DATABASES.collections.CHAT_HISTORY,
                [
                    Query.equal('user_Id', user.$id),
                    Query.orderDesc('createdAt'),
                    Query.limit(100)
                ]
            );

            const formattedChats = response.documents.map(doc => ({
                id: doc.$id,
                title: doc.title || doc.messages ? JSON.parse(doc.messages)[0]?.message?.slice(0, 30) + "..." : "New Chat",
                preview: doc.messages ? JSON.parse(doc.messages)[0]?.message || "New Chat" : "New Chat",
                messages: doc.messages ? JSON.parse(doc.messages) : [],
                createdAt: new Date(doc.createdAt),
                updatedAt: new Date(doc.updatedAt)
            }));

            setChats(formattedChats);

            if (chatId) {
                const selectedChat = formattedChats.find(chat => chat.id === chatId);
                if (selectedChat) {
                    setActiveChat(selectedChat);
                    setMessages(selectedChat.messages);
                    setChatTitle(selectedChat.title || 'New Chat');
                } else {
                    navigate('/chat');
                }
            }
        } catch (error) {
            console.error('Error fetching chats:', error);
        }
    };

    const handleSendMessage = async (message, files = []) => {
        if (!message.trim() || !user?.$id) return;

        const userMessage = {
            id: Date.now(),
            message: message.trim(),
            files,
            role: 'user',
            timestamp: new Date().toISOString()
        };

        const updatedMessages = [...messages, userMessage];
        setMessages(updatedMessages);
        setIsLoading(true);

        try {
            // Generate title if this is the first message
            let newTitle = chatTitle;
            if (messages.length === 0) {
                newTitle = await generateAndSetChatTitle(message);
            }

            const aiResponse = await generateChatCompletion(updatedMessages);

            const assistantMessage = {
                id: Date.now(),
                message: aiResponse,
                role: 'assistant',
                timestamp: new Date().toISOString()
            };

            const finalMessages = [...updatedMessages, assistantMessage];
            setMessages(finalMessages);
            if (activeChat) {
                await databases.updateDocument(
                    DATABASES.MAIN,
                    DATABASES.collections.CHAT_HISTORY,
                    activeChat.id,
                    {
                        user_Id: user.$id,
                        messages: JSON.stringify(finalMessages),
                        title: newTitle,
                        updatedAt: new Date().toISOString()
                    }
                );

                setChats(prevChats =>
                    prevChats.map(chat =>
                        chat.id === activeChat.id
                            ? {
                                ...chat,
                                title: newTitle,
                                preview: message,
                                messages: finalMessages,
                                updatedAt: new Date()
                            }
                            : chat
                    )
                );
            } else {
                const newChatDoc = await databases.createDocument(
                    DATABASES.MAIN,
                    DATABASES.collections.CHAT_HISTORY,
                    ID.unique(),
                    {
                        user_Id: user.$id,
                        messages: JSON.stringify(finalMessages),
                        title: newTitle,
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString()
                    }
                );

                const newChat = {
                    id: newChatDoc.$id,
                    title: newTitle,
                    preview: message,
                    messages: finalMessages,
                    createdAt: new Date(newChatDoc.createdAt),
                    updatedAt: new Date(newChatDoc.updatedAt)
                };

                setChats(prev => [newChat, ...prev]);
                setActiveChat(newChat);
                navigate(`/chat/${newChat.id}`);
            }
        } catch (error) {
            console.error('Error handling message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNewChat = useCallback(() => {
        setActiveChat(null);
        setMessages([]);
        setChatTitle('New Chat');
        navigate('/chat');
    }, [navigate]);

    const handleSelectChat = useCallback((chat) => {
        setActiveChat(chat);
        setMessages(chat.messages);
        setChatTitle(chat.title || 'New Chat');
        setIsSidebarOpen(false);
        navigate(`/chat/${chat.id}`);
    }, [navigate]);

    const handleDeleteChat = useCallback(async (chatId) => {
        try {
            await databases.deleteDocument(
                DATABASES.MAIN,
                DATABASES.collections.CHAT_HISTORY,
                chatId
            );

            setChats(prev => prev.filter(chat => chat.id !== chatId));

            if (activeChat?.id === chatId) {
                const remainingChats = chats.filter(chat => chat.id !== chatId);
                if (remainingChats.length > 0) {
                    const firstChat = remainingChats[0];
                    setActiveChat(firstChat);
                    setMessages(firstChat.messages);
                    setChatTitle(firstChat.title || 'New Chat');
                    navigate(`/chat/${firstChat.id}`);
                } else {
                    setActiveChat(null);
                    setMessages([]);
                    setChatTitle('New Chat');
                    navigate('/chat');
                }
            }
        } catch (error) {
            console.error('Error deleting chat:', error);
        }
    }, [activeChat, chats, navigate]);

    return (
        <div className="h-screen w-screen fixed inset-0 flex bg-white dark:bg-black
                     text-black dark:text-white antialiased overflow-hidden transition-colors duration-300">
            <GlowingBackground />
            {Array.from({ length: 15 }).map((_, i) => (
                <FloatingParticle key={i} delay={i * 0.3} />
            ))}

            <Sidebar
                isOpen={isSidebarOpen}
                setIsOpen={setIsSidebarOpen}
                onNewChat={handleNewChat}
                activeChat={activeChat}
                onSelectChat={handleSelectChat}
                chats={chats}
                onDeleteChat={handleDeleteChat}
            />
            <div className="flex-1 flex flex-col min-w-0 relative isolate">
                {activeChat && (
                    <ChatHeader
                        onMenuToggle={() => setIsSidebarOpen(!isSidebarOpen)}
                        isSidebarOpen={isSidebarOpen}
                        title={chatTitle}
                        messages={messages}
                    />
                )}

                <main className="flex-1 relative">
                    <div className="absolute inset-0 overflow-y-auto overflow-x-hidden
                                custom-scrollbar scroll-smooth">
                        {messages.length === 0 ? (
                            <EmptyState onSendMessage={handleSendMessage} userName={userName} />
                        ) : (
                            <div className="max-w-3xl mx-auto px-4 py-6 space-y-6">
                                <AnimatePresence mode="popLayout">
                                    {messages.map((msg) => (
                                        <MessageContent
                                            key={msg.id}
                                            message={msg.message}
                                            role={msg.role}
                                            files={msg.files}
                                        />
                                    ))}
                                </AnimatePresence>
                                {isLoading && <ChatLoading />}
                                <div ref={messagesEndRef} className="h-32" />
                            </div>
                        )}
                    </div>
                </main>

                {messages.length > 0 && (
                    <div className="border-t border-black/10 dark:border-white/10
                                backdrop-blur-xl bg-white/70 dark:bg-black/70
                                relative shadow-lg">
                        <div className="max-w-3xl mx-auto p-4 relative">
                            <ChatInput
                                onSubmit={handleSendMessage}
                                isLoading={isLoading}
                                disabled={!user}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
