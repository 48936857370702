// src/contexts/AuthContext.jsx
import { useState, useEffect, createContext, useContext } from 'react';
import { account, databases, DATABASES, verifyConfig } from '../lib/appwrite-config';
import { ID } from 'appwrite';

const AuthContext = createContext();

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        // Verify configuration on mount
        if (!verifyConfig()) {
            setLoading(false);
            return;
        }

        const initializeAuth = async () => {
            try {
                const session = await account.get();
                setUser(session);
            } catch (error) {
                // Only log error if it's not the expected unauthorized error
                if (!error.message?.includes('missing scope')) {
                    console.error('Auth initialization error:', error);
                }
                setUser(null);
            } finally {
                setLoading(false);
                setInitialized(true);
            }
        };

        initializeAuth();

        // Check session status periodically
        const checkInterval = setInterval(async () => {
            if (initialized) {
                try {
                    const session = await account.get();
                    setUser(session);
                } catch {
                    setUser(null);
                }
            }
        }, 30000); // Check every 30 seconds

        return () => clearInterval(checkInterval);
    }, [initialized]);

    const login = async (email, password) => {
        try {
            setLoading(true);
            await account.createEmailPasswordSession(email, password);
            const session = await account.get();
            setUser(session);
            return { success: true };
        } catch (error) {
            console.error('Login error:', error);
            return {
                success: false,
                error: error?.message || 'Failed to login. Please check your credentials.'
            };
        } finally {
            setLoading(false);
        }
    };

    const register = async (email, password, name) => {
        try {
            setLoading(true);
            // Create user account
            const response = await account.create(
                ID.unique(),
                email,
                password,
                name
            );

            // Create user document in database
            await databases.createDocument(
                DATABASES.MAIN,
                DATABASES.collections.USERS,
                ID.unique(),
                {
                    email: response.email,
                    name: response.name,
                    status: 'active',
                    createdAt: new Date().toISOString()
                }
            );

            // Auto-login after registration
            return await login(email, password);
        } catch (error) {
            console.error('Registration error:', error);
            return {
                success: false,
                error: error?.message || 'Failed to create account.'
            };
        } finally {
            setLoading(false);
        }
    };

    const createChatHistory = async (messages) => {
        try {
            if (!user?.$id) throw new Error('User not authenticated');

            return await databases.createDocument(
                DATABASES.MAIN,
                DATABASES.collections.CHAT_HISTORY,
                ID.unique(),
                {
                    userId: user.$id,
                    messages: JSON.stringify(messages),
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                }
            );
        } catch (error) {
            console.error('Create chat history error:', error);
            throw error;
        }
    };

    const updateChatHistory = async (documentId, messages) => {
        try {
            if (!user?.$id) throw new Error('User not authenticated');

            return await databases.updateDocument(
                DATABASES.MAIN,
                DATABASES.collections.CHAT_HISTORY,
                documentId,
                {
                    messages: JSON.stringify(messages),
                    updatedAt: new Date().toISOString()
                }
            );
        } catch (error) {
            console.error('Update chat history error:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            setLoading(true);
            await account.deleteSession('current');
            setUser(null);
            return { success: true };
        } catch (error) {
            console.error('Logout error:', error);
            return {
                success: false,
                error: error?.message || 'Failed to logout.'
            };
        } finally {
            setLoading(false);
        }
    };

    const value = {
        user,
        loading,
        isAuthenticated: !!user,
        login,
        register,
        logout,
        createChatHistory,
        updateChatHistory
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}