// Navbar.jsx
import React, { useState, useEffect } from 'react';
import { Menu, X, ArrowRight, Brain } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 20);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Close mobile menu when route changes
    useEffect(() => {
        setIsOpen(false);
    }, [location.pathname]);

    const isActiveLink = (path) => {
        return location.pathname === path ? 'opacity-100' : 'opacity-70 hover:opacity-100';
    };

    return (
        <>
            <nav
                className={`fixed w-full z-50 transition-all duration-300 ${
                    scrolled || isOpen
                        ? 'bg-white/95 dark:bg-black/95 backdrop-blur-sm border-b border-black/10 dark:border-white/10'
                        : 'bg-transparent border-transparent'
                }`}
            >
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-20">
                        {/* Logo */}
                        <Link
                            to="/"
                            className="flex items-center space-x-2 hover:opacity-90 transition-opacity"
                        >
                            <Brain className="w-6 h-6" />
                            <span className="text-2xl font-bold">TranscendenceX</span>
                        </Link>

                        {/* Desktop Navigation */}
                        <div className="hidden md:flex items-center space-x-12">
                            <Link
                                to="/"
                                className={`text-base font-medium transition-opacity ${isActiveLink('/')}`}
                            >
                                Home
                            </Link>
                            <Link
                                to="/about"
                                className={`text-base font-medium transition-opacity ${isActiveLink('/about')}`}
                            >
                                About
                            </Link>
                            <Link
                                to="/chat"
                                className="px-6 py-2.5 bg-black dark:bg-white text-white dark:text-black rounded-lg
                                         hover:opacity-90 transition-all duration-300 transform hover:scale-105
                                         font-medium flex items-center shadow-lg group"
                            >
                                Try Now
                                <ArrowRight className="ml-2 w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" />
                            </Link>
                        </div>

                        {/* Mobile Menu Button */}
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="md:hidden p-2 hover:opacity-70 transition-opacity"
                            aria-label="Toggle menu"
                        >
                            {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                        </button>
                    </div>

                    {/* Mobile Navigation */}
                    <div
                        className={`md:hidden absolute left-0 right-0 bg-white dark:bg-black border-b border-black/10 dark:border-white/10 transition-all duration-300 ease-in-out ${
                            isOpen
                                ? 'opacity-100 translate-y-0'
                                : 'opacity-0 -translate-y-4 pointer-events-none'
                        }`}
                    >
                        <div className="py-4 space-y-4 px-4 sm:px-6 lg:px-8">
                            <Link
                                to="/"
                                className={`block py-2 text-base font-medium transition-opacity ${isActiveLink('/')}`}
                            >
                                Home
                            </Link>
                            <Link
                                to="/about"
                                className={`block py-2 text-base font-medium transition-opacity ${isActiveLink('/about')}`}
                            >
                                About
                            </Link>
                            <Link
                                to="/chat"
                                className="block px-4 py-2.5 bg-black dark:bg-white text-white dark:text-black
                                         rounded-lg hover:opacity-90 transition-all duration-300
                                         text-center font-medium shadow-lg"
                            >
                                Try Now
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Backdrop for mobile menu */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black/20 dark:bg-white/10 backdrop-blur-sm z-40"
                    onClick={() => setIsOpen(false)}
                    aria-hidden="true"
                />
            )}
        </>
    );
};

export default Navbar;