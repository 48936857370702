import React from 'react';
import {
    Sparkles,
    BarChart3,
    Brain,
    Beaker,
    Cloud,
    ShieldCheck,
    GraduationCap,
    Microscope,
    Cpu,
    Activity,
    Users
} from 'lucide-react';
import Navbar from '../components/navbar.jsx';

const customStyles = `
@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0px); }
}

@keyframes slide-up {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

@keyframes pulse-green {
    0%, 100% { background-color: rgba(34, 197, 94, 0.1); }
    50% { background-color: rgba(34, 197, 94, 0.2); }
}

.animate-float {
    animation: float 6s ease-in-out infinite;
}

.animate-slide-up {
    animation: slide-up 0.6s ease-out forwards;
}

.animate-pulse-green {
    animation: pulse-green 2s ease-in-out infinite;
}

.text-gradient {
    background: linear-gradient(to right, #000 0%, #22c55e 50%, #000 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 200% auto;
    animation: textGradient 3s linear infinite;
    line-height: 1.4;
    padding-bottom: 0.5rem;
}

.dark .text-gradient {
    background: linear-gradient(to right, #fff 0%, #22c55e 50%, #fff 100%);
    -webkit-background-clip: text;
    background-clip: text;
}

@keyframes textGradient {
    to {
        background-position: 200% center;
    }
}

.hover-green-glow:hover {
    box-shadow: 0 0 20px rgba(34, 197, 94, 0.5);
}
`;

const AboutSection = ({ title, children, imagePosition = 'right', badge, imageSrc }) => (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 items-center py-8 lg:py-16 animate-slide-up">
        {imagePosition === 'left' && (
            <div className="relative group order-2 lg:order-1">
                <div className="absolute inset-0 bg-gradient-to-r from-green-300/20 to-green-500/20 dark:from-green-500/20 dark:to-green-700/20 rounded-2xl blur-xl transition-opacity duration-300 opacity-0 group-hover:opacity-100"></div>
                <div className="relative overflow-hidden rounded-2xl border border-green-500/20 group-hover:scale-105 transition-transform duration-300 hover-green-glow">
                    <img
                        src={imageSrc}
                        alt={title}
                        className="w-full transform group-hover:scale-105 transition-transform duration-300 object-cover aspect-video"
                        loading="lazy"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
                </div>
            </div>
        )}
        <div className="space-y-4 lg:space-y-6 order-1 lg:order-2">
            {badge && (
                <div className="inline-flex items-center px-3 py-1.5 lg:px-4 lg:py-2 rounded-full bg-green-500/10 dark:bg-green-500/20 text-sm font-medium border border-green-500/20 animate-pulse-green">
                    <Sparkles className="w-4 h-4 mr-2 text-green-500" />
                    {badge}
                </div>
            )}
            <h2 className="text-2xl lg:text-4xl font-bold text-gradient mb-4">{title}</h2>
            <div className="space-y-4 text-black/70 dark:text-white/70">
                {children}
            </div>
        </div>
        {imagePosition === 'right' && (
            <div className="relative group order-2">
                <div className="absolute inset-0 bg-gradient-to-r from-green-300/20 to-green-500/20 dark:from-green-500/20 dark:to-green-700/20 rounded-2xl blur-xl transition-opacity duration-300 opacity-0 group-hover:opacity-100"></div>
                <div className="relative overflow-hidden rounded-2xl border border-green-500/20 group-hover:scale-105 transition-transform duration-300 hover-green-glow">
                    <img
                        src={imageSrc}
                        alt={title}
                        className="w-full transform group-hover:scale-105 transition-transform duration-300 object-cover aspect-video"
                        loading="lazy"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
                </div>
            </div>
        )}
    </div>
);

const FeatureCard = ({ icon: Icon, title }) => (
    <div className="relative group animate-slide-up">
        <div className="absolute inset-0 bg-gradient-to-r from-green-300/20 to-green-500/20 dark:from-green-500/20 dark:to-green-700/20 rounded-xl blur-xl transition-opacity duration-300 opacity-0 group-hover:opacity-100"></div>
        <div className="relative p-4 lg:p-6 rounded-xl bg-white/80 dark:bg-black/80 border border-green-500/20 backdrop-blur-sm hover:border-green-500/40 transition-all duration-300 group-hover:scale-105 hover-green-glow">
            <div className="h-10 w-10 rounded-lg bg-gradient-to-r from-green-500 to-green-600 flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300">
                <Icon className="h-5 w-5 text-white" />
            </div>
            <h3 className="text-base lg:text-lg font-semibold text-black dark:text-white">{title}</h3>
        </div>
    </div>
);

const Footer = () => (
    <footer className="py-6 border-t border-green-500/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center text-sm text-black/60 dark:text-white/60">
            © 2024 TranscendenceX. All rights reserved.
        </div>
    </footer>
);

const About = () => {
    const features = [
        { icon: BarChart3, title: "Data Integration and Analysis Tools" },
        { icon: Brain, title: "AI and ML-Driven Research Capabilities" },
        { icon: Beaker, title: "Organoid Training Simulation Platforms" },
        { icon: Cloud, title: "Cloud Storage and Collaboration Solutions" },
        { icon: ShieldCheck, title: "Ethical and Compliance Management" },
        { icon: GraduationCap, title: "Educational Resources and Training" },
        { icon: Microscope, title: "Experiment Simulation Frameworks" },
        { icon: Cpu, title: "Neuromorphic Computing Integration" },
        { icon: Activity, title: "Performance Monitoring Features" },
        { icon: Users, title: "Community Collaboration Platforms" }
    ];

    return (
        <>
            <style>{customStyles}</style>
            <Navbar />
            <div className="min-h-screen bg-white dark:bg-black text-black dark:text-white">
                {/* Main Content */}
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 lg:pt-20 leading-relaxed pb-2 space-y-8 lg:space-y-16">
                    <AboutSection
                        title="Reimagining Intelligence"
                        badge="Our Vision"
                        imageSrc="/intelligence.jpg"
                    >
                        <p className="text-base lg:text-lg leading-relaxed">
                            While artificial intelligence has achieved remarkable progress in recent years, we are only scratching the surface of what is possible. Current AI systems excel at pattern recognition and task-specific solutions, but they lack the adaptability, energy efficiency, and ingenuity that define human cognition.
                        </p>
                        <p className="text-base lg:text-lg leading-relaxed">
                            To unlock the next wave of innovation, we are building systems that harness the raw computational power and learning capabilities of lab-grown brain organoids to bridge the gap between artificial and biological intelligence.
                        </p>
                    </AboutSection>

                    <AboutSection
                        title="Who We Are"
                        imagePosition="left"
                        badge="Our Identity"
                        imageSrc="/research2.jpg"
                    >
                        <p className="text-base lg:text-lg leading-relaxed">
                            TranscendenceX is at the forefront of the emerging field of Organoid Intelligence. Our mission is to create the next generation of biocomputers, powered by brain organoids, capable of learning, reasoning, and adapting in ways that current silicon-based systems cannot. By combining breakthroughs in neuroscience, bioengineering, and artificial intelligence, we aim to pioneer a new era of biocomputing with profound implications for science, technology, and humanity. TranscendenceX is being developed by Space&Miller LLC, a U.S.-based tech company founded by Jeremy Space.
                        </p>
                        <p className="text-base lg:text-lg leading-relaxed">
                            By combining breakthroughs in neuroscience, bioengineering, and artificial intelligence, we aim to pioneer a new era of biocomputing with profound implications for science, technology, and humanity.
                        </p>
                    </AboutSection>

                    <AboutSection
                        title="Where We're Going"
                        badge="Our Future"
                        imageSrc="/future.jpg"
                    >
                        <p className="text-base lg:text-lg leading-relaxed">
                            Organoid Intelligence represents a paradigm shift in computing, combining the adaptability of biological systems with the precision of AI. By leveraging organoids as living processors, TranscendenceX aims to create systems that can learn faster, process data more efficiently, and evolve their capabilities over time.
                        </p>
                        <p className="text-base lg:text-lg leading-relaxed">
                            Our initial focus is on developing computational models that integrate organoid learning with AI systems, enabling new breakthroughs in areas like drug discovery, neural modeling, and sustainable computing.
                        </p>
                    </AboutSection>

                    <AboutSection
                        title="Who We're Building With"
                        badge="Our Partners"
                        imagePosition="left"
                        imageSrc="/team.jpg"
                    >
                        <p className="text-base lg:text-lg leading-relaxed">
                            We are driven by the belief that this is the right moment to act. TranscendenceX is building a collaborative, interdisciplinary team with expertise spanning neuroscience, artificial intelligence, and ethics to ensure that the systems we create are both revolutionary and responsible.
                        </p>
                        <p className="text-base lg:text-lg leading-relaxed">
                            We are also actively seeking partnerships with academic institutions, research organizations, and industry leaders who share our vision of a biocomputing future. By fostering a strong network of collaborators, we aim to accelerate progress and ensure that Organoid Intelligence reaches its full potential for the benefit of humanity.
                        </p>
                        <p className="text-base lg:text-lg leading-relaxed">
                            Our journey is just beginning, and we invite you to join us as we explore the extraordinary possibilities of Organoid Intelligence. Together, we can transcend the limits of artificial intelligence and unlock the boundless potential of the human brain, one organoid at a time.
                        </p>
                    </AboutSection>

                    {/* Future Features Section */}
                    <div className="py-8 lg:py-16 animate-slide-up">
                        <div className="space-y-4 lg:space-y-6 text-center mb-8 lg:mb-12">
                            <div className="inline-flex items-center px-3 py-1.5 lg:px-4 lg:py-2 rounded-full bg-green-500/10 dark:bg-green-500/20 text-sm font-medium border border-green-500/20 animate-pulse-green">
                                <Sparkles className="w-4 h-4 mr-2 text-green-500" />
                                Platform Features
                            </div>
                            <h2 className="text-2xl lg:text-4xl font-bold text-gradient mb-4">
                                Future Capabilities
                            </h2>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-6">
                            {features.map((feature, index) => (
                                <FeatureCard
                                    key={index}
                                    icon={feature.icon}
                                    title={feature.title}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default About;