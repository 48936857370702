// ChatLoading.jsx
import { motion } from 'framer-motion';
import { Bot } from 'lucide-react';

export default function ChatLoading() {
    return (
        <div className="flex items-start space-x-3">
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                className="flex-shrink-0 h-8 w-8 rounded-lg
                          bg-black/[0.02] dark:bg-white/[0.02]
                          border border-black/[0.05] dark:border-white/[0.05]
                          flex items-center justify-center"
            >
                <Bot className="w-5 h-5 text-gray-500" />
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="flex-1 max-w-[80%]"
            >
                <div className="p-4 rounded-xl
                              bg-black/[0.02] dark:bg-white/[0.02]
                              border border-black/[0.05] dark:border-white/[0.05]
                              shadow-sm">
                    <div className="flex items-center space-x-2">
                        {[0, 1, 2].map((i) => (
                            <motion.div
                                key={i}
                                className="w-2 h-2 rounded-full
                                         bg-black/40 dark:bg-white/40"
                                animate={{
                                    scale: [1, 1.2, 1],
                                    opacity: [0.3, 1, 0.3],
                                }}
                                transition={{
                                    duration: 1,
                                    repeat: Infinity,
                                    delay: i * 0.2,
                                }}
                            />
                        ))}
                    </div>
                </div>
            </motion.div>
        </div>
    );
}